body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.no-scroll::-webkit-scrollbar {
  width: 0px !important;
  display: none;
}
* {
  box-sizing: border-box;
}
.App {
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
}
.HomeContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  background: url("./images/bg.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.BodySection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 90%;
  width: 70%;
  box-sizing: border-box;
  background-color: #f2f2f2;
  opacity: 0.9;
  border-radius: 30px;
  margin-left: -1em;
  margin-right: 1em;
  box-sizing: border-box;
  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 0;
  }
}
.blinkAnimation {
  animation: blink 1s linear infinite;
}
.modal {
  position: relative;
  height: 70vh;
  width: 70vw;
  margin: auto;
  top: 20%;
  padding: 2em;
  border-radius: 30px;
  background-color: #faf9f6;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.4);
  opacity: 1;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  padding: 2em;
  padding-right: 5em;
  box-sizing: border-box;
}
.page404 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 2em;
  padding-right: 5em;
  background: url("./images/404.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-sizing: border-box;
}

.activeLink {
  background-color: #fff;
  color: #000;
  border-radius: 30px;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.error {
  padding: 20px;
  background-color: #f44336; /* Red */
  color: white;
  margin-bottom: 15px;
  border-radius: 15px;
  width: 100%;
  text-align: center;
}
